import { Injectable, inject } from '@angular/core';
import { IUserApiService } from './user-api.interface';
import { Observable, map, take } from 'rxjs';
import { UserCreate } from '../domain/User-create.model';
import { UserUpdate } from '../domain/User-update.model';
import { User } from '../domain/User.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { ResponseApi } from '../../../shared/infrastructure/models/response-api.interface';
import { UserApiModel } from './models/user-api.model';
import { Module } from '../../modules/domain/Module.model';
import { Role } from '../../roles/domain/Role.model';
import { ModuleApiModel } from '../../modules/infrastructure/models/module-api.model';
import { RoleApiModel } from '../../roles/infrastructure/models/role-api.model';

@Injectable({
  providedIn: 'root',
})
export class UserApiService implements IUserApiService {
  private _http = inject(HttpClient);
  private readonly BASE_URL = environment.baseUrl;

  private _setMapUser(user: UserApiModel): User {
    return {
      id: user.id,
      uuid: user.uuid,
      createdAt: user.createdAt,
      updatedAt: user.updatedAt,
      firstName: user.firstName,
      lastName: user.lastName,
      username: user.username,
      email: user.email,
      idCarrierLine: user.idCarrierLine,
      idCompanyBranch: user.idCompanyBranch,
      anyDeskKey: user.anyDeskKey,
      extension: user.extension,
      idEmployee: user.idEmployee,
      avatar: user.avatar,
      position: user.position,
      employeeCode: user.employeeCode,
    };
  }

  private _setMapUsers(usersList: UserApiModel[]): User[] {
    return usersList.map((user) => this._setMapUser(user));
  }

  private _setMapModulesByUser(modules: ModuleApiModel[]): Module[] {
    return modules.map((module) => {
      return { id: module.ID, name: module.name, createdAt: module.CreatedAt };
    });
  }

  private _setMapRolesByUser(roles: RoleApiModel[]): Role[] {
    return roles.map((role) => {
      return {
        id: role.ID,
        name: role.name,
        createdAt: role.CreatedAt,
        idModule: role.idModule,
      };
    });
  }

  getUsers(): Observable<User[]> {
    const url = `${this.BASE_URL}users`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.ok) return [];
        return this._setMapUsers(response.results as UserApiModel[]);
      })
    );
  }

  getUser(id: number): Observable<User | null> {
    const url = `${this.BASE_URL}users/${id}`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.ok) return null;
        return this._setMapUser(response.results as UserApiModel);
      })
    );
  }

  createUser(user: UserCreate): Observable<boolean> {
    const url = `${this.BASE_URL}users`;
    const headers = new HttpHeaders();
    return this._http.post<ResponseApi>(url, user, { headers }).pipe(
      take(1),
      map((response) => {
        return response.ok;
      })
    );
  }

  updateUser(id: number, user: UserUpdate): Observable<User | null> {
    const url = `${this.BASE_URL}users/${id}`;
    const headers = new HttpHeaders();
    return this._http.put<ResponseApi>(url, user, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.ok) return null;
        return this._setMapUser(response.results as UserApiModel);
      })
    );
  }

  updateUserPassword(id: number, password: string): Observable<boolean> {
    const url = `${this.BASE_URL}users/${id}/password`;
    const headers = new HttpHeaders();
    return this._http.patch<ResponseApi>(url, { password }, { headers }).pipe(
      take(1),
      map((response) => response.ok)
    );
  }

  deleteUser(id: number): Observable<boolean> {
    const url = `${this.BASE_URL}users/${id}`;
    const headers = new HttpHeaders();
    return this._http.delete<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        return response.ok;
      })
    );
  }

  getModulesByUser(id: number): Observable<Module[]> {
    const url = `${this.BASE_URL}users/${id}/modules`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.ok) return [];
        return this._setMapModulesByUser(response.results as ModuleApiModel[]);
      })
    );
  }

  getRolesByUser(id: number): Observable<Role[]> {
    const url = `${this.BASE_URL}users/${id}/roles`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.ok) return [];
        return this._setMapRolesByUser(response.results as RoleApiModel[]);
      })
    );
  }

  assignModuleToUser(idUser: number, idModule: number): Observable<boolean> {
    const url = `${this.BASE_URL}users/${idUser}/assign-module`;
    const headers = new HttpHeaders();
    return this._http.post<ResponseApi>(url, { idModule }, { headers }).pipe(
      take(1),
      map((response) => response.ok)
    );
  }

  assignRolesToUser(
    idUser: number,
    rolesIdList: number[]
  ): Observable<boolean> {
    const url = `${this.BASE_URL}users/${idUser}/assign-role`;
    const headers = new HttpHeaders();
    return this._http.post<ResponseApi>(url, { rolesIdList }, { headers }).pipe(
      take(1),
      map((response) => response.ok)
    );
  }
}
